<template lang="pug">
span
  v-btn(small color='primary' 
    @click="showModalHandler" 
    :loading="loading" 
    outlined
    :disabled="disabled"
  ) Mark as processed

  
  confirmation-dialog(
    v-model="modalShowing"
    :title="modalTitle"
    :descr="modalText"
    @okBtnClicked="send"
    @failBtnClicked="reset"
    @click:outside="reset"
  )
</template>

<script>
import ErrMixin from "@/mixins/errors.mixin"
export default {
  name: 'CandidateRemoveFromQueue',
  mixins: [ErrMixin],
  inject: ['svc', 'markAsProcessed'],

  props: {
    disabled: Boolean
  },

  data: () => ({
    loading: false,
    modalTitle: null,
    modalText: null,
    modalShowing: false
  }),

  computed: {
    Svc() {
      return this.svc()
    }
  },

  methods:{ 
    showModalHandler() {
      this.showConfirmation()
    },

    showConfirmation() {
      this.freeze()
      this.modalTitle = 'Mark as processed'
      this.modalText = 'Are you sure you want to mark as processed this Candidate?'
      this.modalShowing = true
    },

    async send() {
      this.freeze()
      try {
        await this.markAsProcessed()
        this.unFreeze()
      } catch (error) {
        this.unFreeze()
      }
      this.unFreeze()
    },

    freeze() {
      this.loading = true
    },

    unFreeze() {
      this.loading = false
    },

    reset() {
      this.unFreeze()
    }
  },

  components: {
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>